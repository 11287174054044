import {
  GraphQLBaseViewModel,
  getSiteIdParameter,
  getTimezoneForSiteSelector
} from '../../../common';
import { rootState } from '../../../../reducers';
import { getLogger } from 'aurelia-logging';
import { getAlarmIdFromRoute } from '../../selectors';
import { Router } from 'aurelia-router';
import { routeNames } from '../../../../config';
import {
  emptyArray,
  getNewId,
  toObject,
  removeNoneFromArray,
  isNone,
  validateEmail,
  getResetOperator,
  subtractHoursFromNow,
  orderByPredicate,
  triggerOperators,
  ensureNumber
} from '../../../../utility';
import {
  UserService,
  AlarmService,
  ChannelService,
  UnitService
} from '../../../../services';
import {
  IAlarm,
  ISiteChannel,
  IUser,
  IAlarmRecipient,
  IAlarmSubscriptions,
  IAlarmLog,
  IHistoryItem,
  IUnit
} from '../../../../interfaces';
import { autoinject, computedFrom, PLATFORM } from 'aurelia-framework';
import {
  alarmTypes,
  alarmStatistics,
  alarmOperators,
  alarmTypesLowerCase
} from '../../../../models';
import { menuIsOpenForEntity, IAsyncEntity } from '../../../../types';
import {
  toggleExpandMenuOnEntity,
  editAlarm,
  cancelEdit,
  toggleAddRecipientDialog,
  changeRecipientType,
  externalRecipientAddressChanged,
  editExternalRecipient,
  recipientPropertyChanged
} from '../../../../actions';
import './sitedetailsalarms.css';
import gql from 'graphql-tag';
import {
  SiteDetailsAlarmsQuery,
  SiteDetailsAlarmsQueryVariables
} from '../../../../../custom_typings/graphql';
import { I18N } from 'aurelia-i18n';

interface IAlarmUser {
  alarmRecipientId: number;
  email?: string;
  user?: IUser;
}

interface IAlarmDetails {
  entity?: IAlarm;
  recipients: {
    internal: IAlarmUser[];
    external: IAlarmUser[];
  };
  historyItems: IAsyncEntity<IHistoryItem[]>;
  allHistoryItemsAreLoaded: boolean;
  unit: IUnit | undefined;
  channelIsParked: boolean;
}

interface ISiteDetailsAlarmState {
  siteId: number;
  currentAlarm?: IAlarmDetails;
  alarms: {
    system: IAlarm[];
    custom: IAlarm[];
    all: IAlarm[];
    parked: IAlarm[];
  };
  hasAlarms: boolean;
  alarmTypes: {};
  alarmStatisticsMap: {};
  alarmStatistics: Array<{ id: number; value: string }>;
  alarmOperators: Array<{ operator: string; resetOperator: string }>;
  alarmSubscriptions: {} | undefined;
  siteChannelsMap: {};
  siteChannels: ISiteChannel[];
  menuIsOpenForEntity?: menuIsOpenForEntity;
  addRecipientDialogOpen: boolean;
  addRecipientType?: string;
  externalRecipientAddress?: string;
  invalidExternalRecipientAddress: boolean;
  currentlyEditingRecipient?: IAlarmRecipient;

  timezone?: string;

  userSearch: {
    query?: string;
    isSearching: boolean;
    results: IUser[];
  };

  currentAlarmNameTranslated?: string;
}

const mapInternalUsers = (
  recipients: IAlarmRecipient[],
  userService: UserService
): IAlarmUser[] => {
  const userIdsWithTokens = recipients
    .filter(r => !isNone(r.userId) && !isNone(r.userIdToken))
    .map(r => ({ id: r.userId, token: r.userIdToken }));
  const userIds = removeNoneFromArray(userIdsWithTokens.map(it => it.id));
  const tokens = removeNoneFromArray(userIdsWithTokens.map(it => it.token));
  const usersMap = toObject(
    userService.getUsers(userIds, tokens).getEntityOrDefault(emptyArray),
    u => u.userId
  );

  return recipients.map(r => ({
    alarmRecipientId: r.alarmRecipientId,
    user: usersMap[r.userId!]
  }));
};

const mapExternalUsers = (recipients: IAlarmRecipient[]) =>
  recipients.map(r => ({
    alarmRecipientId: r.alarmRecipientId,
    externalEmail: r.externalEmail
  }));

const mapToHistoryItem = (alarmLogs: IAlarmLog[]): IHistoryItem[] =>
  alarmLogs.map(al => {
    return {
      timeStamp: al.timeStamp,
      icon: al.state === 1 ? 'fa-bell-o' : 'fa-bell-slash-o',
      metaInformation: al.state === 1 ? 'Triggered' : 'Reset',
      text: [al.message]
    };
  });

const mapAlarmDetails = (
  { alarmLogs }: rootState,
  siteId: number,
  alarmId: number,
  alarms: IAlarm[],
  userService: UserService,
  alarmService: AlarmService,
  siteChannels: ISiteChannel[],
  unitService: UnitService
): IAlarmDetails => {
  const alarm = alarms.find(alarm => alarm.alarmId === alarmId);

  const siteChannelId = alarm && alarm.siteChannelId;
  const siteChannel = siteChannelId
    ? siteChannels.find(sc => sc.siteChannelId === siteChannelId)
    : undefined;

  const channelIsParked = (siteChannel && siteChannel.isParked) ?? false;

  const unitsFetcher = unitService.fetchUnits();

  const alarmRecipients =
    alarm && alarm.alarmType === 1
      ? alarmService
          .getAlarmRecipientsForAlarm(siteId, alarm.alarmId)
          .getEntityOrDefault(emptyArray)
      : emptyArray;

  const internalAlarmRecipients = removeNoneFromArray(
    alarmRecipients.filter(ar => ar.userId)
  );
  const externalUsers = removeNoneFromArray(
    alarmRecipients.filter(ar => isNone(ar.userId))
  );
  const allIsFetched = alarmLogs.fetchedAllLogsForAlarms.some(
    id => id === alarmId
  );
  const historyItems = alarmService
    .getAlarmLogEntriesForAlarm(siteId, alarmId, allIsFetched, true)
    .map(mapToHistoryItem)
    .getAsyncEntity();

  return {
    entity: alarm,
    channelIsParked,
    unit: unitsFetcher
      .map(u =>
        u.find(unit => siteChannel && siteChannel.unitId === unit.unitId)
      )
      .getEntityOrUndefined(),
    recipients: {
      internal: mapInternalUsers(internalAlarmRecipients, userService),
      external: mapExternalUsers(externalUsers)
    },
    historyItems,
    allHistoryItemsAreLoaded: allIsFetched
  };
};

const mapAlarmSubscriptions = (subscriptions: IAlarmSubscriptions) => ({
  2: subscriptions.lowLevel,
  3: subscriptions.preLowLevel,
  4: subscriptions.tankCalibration,
  5: subscriptions.offline,
  6: subscriptions.percentCapacity30,
  7: subscriptions.maintenance,
  8: subscriptions.doseCalibration,
  9: subscriptions.seriousDoseCalibration,
  10: subscriptions.brokenSensor
});

const mapState = (
  userService: UserService,
  alarmService: AlarmService,
  channelService: ChannelService,
  unitService: UnitService,
  i18n: I18N
) => (state: rootState): ISiteDetailsAlarmState => {
  const siteId = getSiteIdParameter(state).valueOr(0);

  const alarmId = getAlarmIdFromRoute(state);
  const assuredAlarmId = alarmId.valueOr(0);

  const allAlarms =
    siteId > 0
      ? orderByPredicate(
          alarmService.getAlarmsBySiteId(siteId).getEntityOrDefault(emptyArray),
          a => a.active,
          'desc'
        )
      : emptyArray;

  const siteChannels =
    siteId > 0
      ? channelService
          .getSiteChannelsForSite(siteId)
          .getEntityOrDefault(emptyArray)
      : emptyArray;

  const parkedSiteChannelIds = siteChannels.filter(c => c.isParked).map(c => c.siteChannelId);

  const currentAlarm =
    assuredAlarmId > 0
      ? mapAlarmDetails(
          state,
          siteId,
          assuredAlarmId,
          allAlarms,
          userService,
          alarmService,
          siteChannels,
          unitService
        )
      : undefined;

  const invalidExternalRecipientAddress = state.sitedetailsAlarms
    .externalRecipientAddress
    ? validateEmail(state.sitedetailsAlarms.externalRecipientAddress)
    : false;

  let currentAlarmNameTranslated = '';
  if (
    currentAlarm?.entity?.alarmType !== undefined &&
    !isNone(currentAlarm?.entity?.alarmType)
  ) {
    currentAlarmNameTranslated = i18n.tr(
      alarmTypesLowerCase[currentAlarm.entity.alarmType]
    );
  }

  return {
    siteId,
    alarms: {
      system: allAlarms.filter(a => a.alarmType > 1),
      custom: allAlarms.filter(a => a.alarmType === 1),
      all: allAlarms,
      parked: allAlarms.filter(
        a =>
          a.siteChannelId && parkedSiteChannelIds.indexOf(a.siteChannelId) >= 0
      ),
    },
    alarmTypes,
    alarmStatisticsMap: toObject(alarmStatistics, a => a.id),
    alarmStatistics,
    alarmOperators,
    alarmSubscriptions: userService
      .getCurrentUser()
      .map(u => mapAlarmSubscriptions(u.alarmSubscriptions))
      .getEntityOrUndefined(),
    currentAlarm,
    hasAlarms: allAlarms.length > 0,
    siteChannelsMap: toObject(siteChannels, sc => sc.siteChannelId),
    siteChannels,
    menuIsOpenForEntity: state.application.menuIsOpenForEntity,

    addRecipientDialogOpen: state.sitedetailsAlarms.addRecipientDialogOpen,
    addRecipientType: state.sitedetailsAlarms.addRecipientType,

    externalRecipientAddress: state.sitedetailsAlarms.externalRecipientAddress,
    invalidExternalRecipientAddress,

    currentlyEditingRecipient:
      state.sitedetailsAlarms.currentlyEditingRecipient,

    timezone: getTimezoneForSiteSelector(state),

    userSearch: {
      isSearching: state.sitedetailsAlarms.isSearching,
      query: state.sitedetailsAlarms.userSearchQuery,
      results: orderByPredicate(
        state.sitedetailsAlarms.searchResults,
        u => u.name,
        'asc'
      )
    },
    currentAlarmNameTranslated
  };
};

@autoinject()
export class SiteDetailsAlarms extends GraphQLBaseViewModel<
  ISiteDetailsAlarmState,
  SiteDetailsAlarmsQuery,
  SiteDetailsAlarmsQueryVariables
> {
  menuForRecipients = getNewId();
  menuForRecipientItem = getNewId();
  menuForSiteChannelDropdown = getNewId();
  menuForAlarmStatisticsDropdown = getNewId();
  menuForOperatorDropdown = getNewId();
  createAlarmEditor = getNewId();

  constructor(
    private router: Router,
    private userService: UserService,
    private alarmService: AlarmService,
    channelService: ChannelService,
    unitService: UnitService,
    i18n: I18N
  ) {
    super(
      getLogger('SiteDetailsAlarms'),
      mapState(userService, alarmService, channelService, unitService, i18n)
    );
  }

  query = gql`
    query SiteDetailsAlarmsQuery($siteId: Int!) {
      site(siteId: $siteId) {
        alarmsAreMuted
        disabledSystemAlarms
      }
    }
  `;

  activate(params: { id: string }) {
    this.variables = {
      siteId: ensureNumber(params.id)
    };
  }

  createAlarm = () =>
    this.router.navigateToRoute(
      routeNames.sitedetailsAlarmsCreateOrEdit,
      undefined
    );

  @computedFrom('data')
  get disabledSystemAlarms() {
    return this.data?.site?.disabledSystemAlarms;
  }

  @computedFrom('state.alarms', 'disabledSystemAlarms')
  get alarmListAlarms() { 
    return this.disabledSystemAlarms ? this.state.alarms.custom : this.state.alarms.all
  }

  editAlarm = (alarmId: number) =>
    this.router.navigateToRoute(routeNames.sitedetailsAlarmsCreateOrEdit, {
      alarmId
    });

  navigateToAlarm = ({ alarmId }: IAlarm) =>
    this.router.navigateToRoute(routeNames.sitedetailsAlarms, { alarmId });

  dispatchToggleExpandMenuOnEntity = (menuItemId: menuIsOpenForEntity) =>
    this.dispatch(toggleExpandMenuOnEntity(menuItemId));

  dispatchEditAlarm = (alarm: IAlarm) => this.dispatch(editAlarm(alarm));

  dispatchCancelEdit = () => this.dispatch(cancelEdit());

  dispatchToggleAddRecipientDialog = () =>
    this.dispatch(toggleAddRecipientDialog());

  dispatchEditExternalRecipient = (recipient: IAlarmRecipient) =>
    this.dispatch(editExternalRecipient(recipient));

  dispatchChangeRecipientType = (type: 'email' | 'user' | undefined) =>
    this.dispatch(changeRecipientType(type));

  dispatchExternalRecipientAddressChanged = (value: string) =>
    this.dispatch(externalRecipientAddressChanged(value));

  dispatcPropertyChangedOnRecipient = (
    name: keyof IAlarmRecipient,
    value: string
  ) => {
    if (
      this.state.currentlyEditingRecipient &&
      this.state.currentlyEditingRecipient[name] != value
    )
      this.dispatch(recipientPropertyChanged({ name, value }));
  };

  findUser = (query: string) => {
    if (
      this.state.currentAlarm &&
      this.state.currentAlarm.entity &&
      query.length > 0
    ) {
      this.alarmService.findUser(
        this.state.siteId,
        this.state.currentAlarm.entity.alarmId,
        query
      );
    }
  };

  noop = PLATFORM.noop;
  confirmDeleteAlarm: IAlarm | undefined;
  deleteAlarm = async (alarm: IAlarm) => {
    await this.alarmService.deleteAlarm(this.state.siteId, alarm);
    this.confirmDeleteAlarm = undefined;
    this.router.navigateToRoute(routeNames.sitedetailsAlarms, undefined, {
      replace: true
    });
  };

  fetchAllAlarms = () => {
    if (this.state.currentAlarm && this.state.currentAlarm.entity)
      this.alarmService.getAlarmLogEntriesForAlarm(
        this.state.siteId,
        this.state.currentAlarm.entity.alarmId,
        true,
        true
      );
  };

  confirmAcknowledgeAlarm: IAlarm | undefined;
  acknowledgeAlarm = async (alarm: IAlarm) => {
    await this.alarmService.acknowledgeAlarm(this.state.siteId, alarm);

    this.confirmAcknowledgeAlarm = undefined;
    this.router.navigateToRoute(routeNames.sitedetailsAlarms, undefined, {
      replace: true
    });
  };

  addExternalRecipient = (alarmId: number, email: string) =>
    this.alarmService.addExternalRecipient(this.state.siteId, alarmId, email);

  addInternalRecipient = (alarmId: number, userId: number) =>
    this.alarmService.addInternalRecipient(this.state.siteId, alarmId, userId);

  confirmRemoveRecipient: IAlarmRecipient | undefined;
  removeRecipient = async (alarmId: number, recipient: IAlarmRecipient) => {
    await this.alarmService.removeRecipient(
      this.state.siteId,
      alarmId,
      recipient.alarmRecipientId
    );
    this.confirmRemoveRecipient = undefined;
  };
  updateRecipient = (alarmId: number, recipient: IAlarmRecipient) =>
    this.alarmService.updateRecipient(this.state.siteId, alarmId, recipient);

  testRecipient = (alarmId: number, recipient: IAlarmRecipient) =>
    this.alarmService.testRecipient(this.state.siteId, alarmId, recipient);

  getResetOperator = (operator: triggerOperators) => getResetOperator(operator);

  subtractHoursFromNow = (hours: number) => subtractHoursFromNow(hours);

  validateEmail = (email: string) => validateEmail(email);

  subscribeToAlarmType = (alarmTypeId: number) =>
    this.userService.changeAlarmSubscription(alarmTypeId, true);
  unsubscribeToAlarmType = (alarmTypeId: number) =>
    this.userService.changeAlarmSubscription(alarmTypeId, false);

  async toggleMuteAlarms(muted: boolean) {
    await this.runQuery(
      gql`
        mutation ToggleMuteAlarmsMutations(
          $siteIds: [Int!]!
          $muted: Boolean!
        ) {
          setSiteAlarmExclutionsForSites(siteIds: $siteIds, muted: $muted)
        }
      `,
      {
        siteIds: [this.variables?.siteId],
        muted
      }
    );

    await this.revalidateAllActiveQueries();
  }

  async toggleDisableSystemAlarms(disable: boolean) {
    await this.runQuery(
      gql`
        mutation ToggleDisableSystemAlarms(
          $siteIds: [Int!]!
          $disable: Boolean!
        ) {
          updateSites(
            siteIds: $siteIds
            editCustomer: false
            editBuid: false
            editSystemAlarms: true
            systemAlarmsDisabled: $disable
            editSoldTo: false
            editParked: false
            editOrderContact: false
            editTechContact: false
            editSalesContact: false
            editCustomerContact: false
            editHaulier: false
            editAlarmMuted: false
          ) {
            sites {
              siteId
              disabledSystemAlarms
            }
          }
        }
      `,
      {
        siteIds: [this.variables?.siteId],
        disable
      }
    );

    await this.revalidateAllActiveQueries();

  }

  getAlarmStickerType(alarm: IAlarm) {
    if (this.state.alarms.parked.indexOf(alarm) >= 0) return 'parked';

    return alarm.active ? 'error' : 'success';
  }
}
